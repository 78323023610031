import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import BigPostPreview from "../components/bigpostpreview"
import { layoutSmallPosts } from "../components/smallpostpreview"
import HomeSlider from "../components/homeslider"
import Sidebar from "../components/sidebar"
import SEO from "../components/seo"

export const query = graphql`
  query {
    mainPreview: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          ...BigPostPreviewFragment
        }
      }
    }
    smallPreviews: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: 1
      limit: 6
    ) {
      edges {
        node {
          ...SmallPostPreviewFragment
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const smallPreviews = data.smallPreviews.edges.map(x => x.node)

  return (
    <Layout>
      <SEO title="lovethis.world" />
      <div className="content-area">
        <main className="site-main">
          <HomeSlider />

          <div className="bg-c-1 padd-lg-b60">
            <div className="container padd-only-sm">
              <div className="row" />
            </div>
          </div>
        </main>
        {/* <!-- main --> */}
      </div>
      {/* <!-- primary --> */}
    </Layout>
  )
}

export default IndexPage
